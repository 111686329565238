import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Depreciation } from '../models/depreciation.model';
import * as DepreciationActions from '../actions/depreciation.actions';
import { Configs, LoadingState } from 'src/app/_shared/configs.model';

export const featureKey = 'depreciations';

export interface State extends EntityState<Depreciation.Model> {
  // additional entities state properties
  callState: Configs.CallState;
  actionCallState: Configs.CallState;
  isSaved: boolean;
  pageIndex: number | null;
  pageSize: number | null;
  totalPages: number | null;
  totalElementsCount: number | null;
  currentDeprId: string | null;
  dates: string[] | null;
  error: string | null;
}

export const adapter: EntityAdapter<Depreciation.Model> = createEntityAdapter<Depreciation.Model>({
  selectId: (depreciation) => depreciation.uuid,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  callState: LoadingState.INIT,
  actionCallState: LoadingState.INIT,
  pageIndex: null,
  pageSize: 10,
  totalPages: null,
  totalElementsCount: null,
  currentDeprId: null,
  dates: null,
  error: null,
  isSaved: false,
});

export const reducer = createReducer(
  initialState,
  on(DepreciationActions.addDialog, (state) => ({
    ...state,
    isSaved: false,
  })),
  on(DepreciationActions.add, (state) => ({
    ...state,
    actionCallState: LoadingState.LOADING,
  })),
  on(DepreciationActions.addSuccess, (state, { payload }) => {
    return adapter.addOne(payload, {
      ...state,
      error: null,
      totalElementsCount: state.totalElementsCount + 1,
      actionCallState: LoadingState.LOADED,
    });
  }),
  on(DepreciationActions.addFailure, (state, { error }) => ({
    ...state,
    currentDeprId: null,
    error: error?.message,
    actionCallState: LoadingState.LOADED,
  })),
  on(DepreciationActions.save, (state) => ({
    ...state,
    actionCallState: LoadingState.LOADING,
  })),
  on(DepreciationActions.saveFailure, (state) => ({
    ...state,
    actionCallState: LoadingState.LOADED,
  })),
  on(DepreciationActions.saveSuccess, (state, { payload }) => {
    return adapter.updateOne(payload, {
      ...state,
      actionCallState: LoadingState.LOADED,
      isSaved: true,
    });
  }),
  on(DepreciationActions.loadByIdForDialogSuccess, (state, { payload }) =>
    adapter.upsertOne(payload, state)
  ),
  on(DepreciationActions.loadUsedDeprDatesSuccess, (state, { dates }) => ({
    ...state,
    dates,
  })),
  on(DepreciationActions.load, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(
    DepreciationActions.loadSuccess,
    (state, { payload: { data, pageIndex, totalElementsCount, totalPages } }) =>
      adapter.setAll(data, {
        ...state,
        pageIndex,
        totalPages,
        totalElementsCount,
        callState: LoadingState.LOADED,
      })
  ),
  on(DepreciationActions.deleteLastItemSuccess, (state, { uuid }) =>
    adapter.removeOne(uuid, {
      ...state,
      totalElementsCount: state.totalElementsCount - 1,
    })
  ),
  on(DepreciationActions.setCurrentDeprId, (state, { uuid }) => ({
    ...state,
    currentDeprId: uuid,
  })),
  on(DepreciationActions.resetDeprId, (state) => ({
    ...state,
    currentDeprId: null,
  }))
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getTotalElementsCount = (state: State) => state.totalElementsCount;
export const getPageIndex = (state: State) => state.pageIndex;
export const getCurrentDeprId = (state: State) => state.currentDeprId;
export const getError = (state: State) => state.error;
