import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { filter, mergeMap, take, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as VatReportSelectors from '../store/selectors/vat-report.selectors';
import * as VatReportActions from '../store/actions/vat-report.actions';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VatReportInfAResolver {
  private store = inject(Store);


  resolve(snapshot: ActivatedRouteSnapshot) {
    const month = +snapshot.queryParamMap.get('month');
    const year = +snapshot.queryParamMap.get('year');
    const uuid = snapshot.paramMap.get('uuid');

    return this.store.pipe(select(VatReportSelectors.selectById({ uuid }))).pipe(
      filter((res) => !!res),
      take(1),
      mergeMap((res) => {
        if (res.countryCode === 'EE' || res.country === 'EE') {
          this.store.dispatch(VatReportActions.loadInfA({ month, year }));

          return this.store.pipe(select(VatReportSelectors.selectInfA)).pipe(
            filter((val) => !!val),
            take(1)
          );
        } else {
          return of([]);
        }
      })
    );
  }
}
