import { inject, Injectable } from '@angular/core';
import { switchMap, map, catchError, filter } from 'rxjs/operators';
import { of } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { CURRENT_COMPANY_STORE } from '@domains/company';
import { AccountsApiService } from '@domains/account/data-access';
import { NotificationService } from '@shared/notification/notification.service';
import { AccountsPopupsService, filterAccountsByPLSFormat } from '@domains/account';

import * as AccountActions from '../actions/account.actions';

@Injectable()
export class AccountEffects {
  private actions$ = inject(Actions);
  private accounts = inject(AccountsApiService);
  private accountsPopups = inject(AccountsPopupsService);
  private notify = inject(NotificationService);

  private readonly companyStore = inject(CURRENT_COMPANY_STORE);
  private readonly companyID = this.companyStore.id;

  loadLocalAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.Local.load),
      switchMap((params) => {
        return this.accounts.getLocalAccounts(params).pipe(
          map((payload) => AccountActions.Local.loadSuccess({ payload })),
          catchError((error) => of(AccountActions.Local.loadFailure(error)))
        );
      })
    )
  );

  createLocalAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.Local.add),

      switchMap(({ payload }) => {
        return this.accounts.createLocalAccount(payload).pipe(
          map((account) =>
            AccountActions.Local.addSuccess({
              payload: account,
            })
          ),
          catchError((error) => of(AccountActions.Local.addFailure(error)))
        );
      })
    )
  );

  updateLocalAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.Local.update),

      switchMap(({ payload: { uuid, ...body } }) => {
        return this.accounts.updateLocalAccount(uuid, body).pipe(
          map(({ uuid: id, ...changes }) =>
            AccountActions.Local.updateSuccess({
              payload: { id, changes },
            })
          ),
          catchError((error) => of(AccountActions.Local.updateFailure(error)))
        );
      })
    )
  );

  deleteLocalAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.Local.deleteItem),

      switchMap(({ uuid }) =>
        this.accounts.deleteLocalAccount(uuid).pipe(
          map(() =>
            AccountActions.Local.deleteItemSuccess({
              uuid,
            })
          ),
          catchError((error) => of(AccountActions.Local.deleteItemFailure(error)))
        )
      )
    )
  );

  loadGlobalAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.Global.load),
      switchMap((params) => {
        const cacheData = true;
        return this.accounts.getGlobalAccounts(params, cacheData).pipe(
          map((payload) =>
            AccountActions.Global.loadSuccess({
              payload: {
                ...payload,
                data: filterAccountsByPLSFormat(payload.data, params.plsFormat),
              },
            })
          ),
          catchError((error) => of(AccountActions.Global.loadFailure(error)))
        );
      })
    )
  );

  loadGlobalAccountsLabels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.loadLabels),

      switchMap(() => {
        return this.accounts.getLabels().pipe(
          map((payload) => AccountActions.loadLabelsSuccess({ payload })),
          catchError((error) => of(AccountActions.loadLabelsFailure(error)))
        );
      })
    )
  );

  createGlobalAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.Global.add),
      switchMap(({ payload }) => {
        return this.accounts.createGlobalAccount(payload).pipe(
          map((globalAccount) =>
            AccountActions.Global.addSuccess({
              payload: globalAccount,
            })
          ),
          catchError((error) => of(AccountActions.Global.addFailure(error)))
        );
      })
    )
  );

  updateGlobalAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.Global.update),
      switchMap(({ payload: { uuid, ...body } }) => {
        return this.accounts.updateGlobalAccount(uuid, body).pipe(
          map(({ uuid: id, ...changes }) =>
            AccountActions.Global.updateSuccess({
              payload: { id, changes },
            })
          ),
          catchError((error) => of(AccountActions.Global.updateFailure(error)))
        );
      })
    )
  );

  attachLabelToGlobalAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.Global.attachLabel),
      switchMap(({ payload }) =>
        this.accountsPopups.showAttachLabelPopup(payload).pipe(
          filter((label) => !!label),
          switchMap((account) =>
            this.accounts.attachLabelToGlobalAccount(account.uuid, account.label).pipe(
              map(() => AccountActions.Global.attachLabelSuccess()),
              catchError((error) => of(AccountActions.Global.attachLabelFailure(error)))
            )
          )
        )
      )
    )
  );

  reattachLabelToGlobalAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.Global.reattachLabel),
      switchMap(({ payload }) =>
        this.accountsPopups.showAttachLabelPopup(payload).pipe(
          filter((label) => !!label),
          switchMap((account) =>
            this.accounts
              .reattachLabelToGlobalAccount(account.uuid, this.companyID(), account.label)
              .pipe(
                map(() => AccountActions.Global.reattachLabelSuccess()),
                catchError((error) => of(AccountActions.Global.reattachLabelFailure(error)))
              )
          )
        )
      )
    )
  );

  deattachLabelToGlobalAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.Global.deattachLabel),
      switchMap(({ payload: { uuid } }) => {
        return this.accounts.deattachLabelFromGlobalAccount(uuid, this.companyID()).pipe(
          map(() => AccountActions.loadLabels()),
          catchError((error) => of(AccountActions.Global.deattachLabelFailure(error)))
        );
      })
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.Global.deleteItem),
      switchMap(({ uuid }) =>
        this.accounts.deleteGlobalAccount(uuid).pipe(
          map(() =>
            AccountActions.Global.deleteItemSuccess({
              uuid,
            })
          ),
          catchError((error) => of(AccountActions.Global.deleteItemFailure(error)))
        )
      )
    )
  );

  attachingLabelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.Global.attachLabelSuccess, AccountActions.Global.reattachLabelSuccess),
      switchMap(() => {
        AccountActions.loadLabels();
        return this.actions$.pipe(
          ofType(AccountActions.loadLabelsSuccess),
          map(() => {
            this.notify.success(['ntf.attach_success', 'text.label']);
            return AccountActions.Global.closeDialog();
          })
        );
      })
    )
  );
}
