import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDepreciation from '../reducers/depreciation.reducer';
import { LoadingState } from 'src/app/_shared/configs.model';

export const selectState = createFeatureSelector<fromDepreciation.State>(
  fromDepreciation.featureKey
);

export const selectAll = createSelector(selectState, fromDepreciation.selectAll);

export const selectEntities = createSelector(selectState, fromDepreciation.selectEntities);

export const selectIsLoaded = createSelector(
  selectState,
  (state) => state.callState === LoadingState.LOADED
);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.callState === LoadingState.LOADING
);

export const isSaved = createSelector(selectState, (state) => state.isSaved);

export const selectIsActionLoading = createSelector(
  selectState,
  (state) => state.actionCallState === LoadingState.LOADING
);

export const selectTotalElementsCount = createSelector(
  selectState,
  fromDepreciation.getTotalElementsCount
);

export const selectPageIndex = createSelector(selectState, fromDepreciation.getPageIndex);

export const selectById = ({ uuid }) =>
  createSelector(selectEntities, (entities) => entities[uuid]);

export const selectCurrentId = createSelector(selectState, fromDepreciation.getCurrentDeprId);

export const selectDeprError = createSelector(selectState, fromDepreciation.getError);

export const selectSavedDepreciations = createSelector(selectAll, (depr) =>
  depr.filter((d) => d.status === 'SAVED')
);

export const selectCurrentDepr = createSelector(
  selectCurrentId,
  selectEntities,
  (uuid, entities) => entities[uuid]
);
