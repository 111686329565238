import { AfterViewInit, Component, DestroyRef, HostBinding, OnInit, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { LayoutAction } from '@app/_layouts';
import { Animations, inOutPaneAnimation } from '@shared/animations';
import { LoaderOverlayDirective } from '@shared/global-directives/loader-overlay.directive';

import { MainLayoutHeaderComponent } from './main-layout-header/main-layout-header.component';
import { MainLayoutSidenavComponent } from './main-layout-sidenav/main-layout-sidenav.component';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  imports: [
    AsyncPipe,
    MainLayoutHeaderComponent,
    MainLayoutSidenavComponent,
    MatSidenavModule,
    LoaderOverlayDirective,
  ],
  animations: [inOutPaneAnimation, Animations.layoutLoading],
})
export class MainLayoutComponent implements OnInit, AfterViewInit {
  private facade = inject(LayoutService);
  private destroyRef = inject(DestroyRef);

  @HostBinding('class.sidebar-minimized') sidebarMinimized = false;
  @HostBinding('class.no-transition') disableTransition = true;
  @HostBinding('class.sidebar-hidden') sidebarHidden = false;

  readonly selectedCompany = this.facade.company;

  theme$ = this.facade.theme$;
  authUser$ = this.facade.authUser$;
  menuItems$ = this.facade.sidebarMenuItems$;
  headerMenuItems$ = this.facade.headerMenuItems$;
  selectedLanguage$ = this.facade.headerSelectedLanguage$;
  languages$ = this.facade.headerLanguages$;
  loading$ = this.facade.loading$;
  sidebarHidden$ = this.facade.sidebarHidden$;
  sidebarMinimized$ = this.facade.sidebarMinimized$;
  basePath$ = this.facade.basePath$;
  isViewerActive$ = this.facade.fileViewerTabIsActive$;
  showCompanyInfo$ = this.facade.showCompanyInfo$;
  headerLabel$ = this.facade.headerLabel$;

  constructor() {
    this.sidebarMinimized$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((sidebarMinimized) => {
        this.sidebarMinimized = sidebarMinimized;
      });

    this.sidebarHidden$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((hidden) => {
      this.sidebarHidden = hidden;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.disableTransition = false;
    });
  }

  ngOnInit(): void {}

  layoutActionHandler(action: LayoutAction): void {
    this.facade.emitAction(action);
  }

  onAccordionItemToggle(): void {
    if (this.sidebarMinimized) {
      this.facade.maximizeSidebar();
    }
  }

  onSidebarToggle(): void {
    this.facade.toggleSidebar();
  }
}
