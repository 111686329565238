import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { take, filter, switchMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { PermissionsService } from '@api/permissions.service';

import * as FinStatementActions from '@store/actions/financial-statements.actions';
import * as FinStatementSelectors from '@store/selectors/financial-statements.selectors';

@Injectable({
  providedIn: 'root',
})
export class BalanceSheetGuard {
  private store = inject(Store);
  private permissions = inject(PermissionsService);


  waitForDataToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(FinStatementSelectors.selectStatementIsLoaded),
      filter((loaded) => loaded),
      take(1)
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const from = route.queryParamMap.get('from');
    const to = route.queryParamMap.get('to');

    if (!from || !to) {
      return this.permissions.accessGuard('balance_sheet');
    } else {
      this.store.dispatch(
        FinStatementActions.generateBalanceSheet({
          from,
          to,
        })
      );
      return this.waitForDataToLoad().pipe(
        switchMap(() => this.permissions.accessGuard('balance_sheet'))
      );
    }
  }
}
