import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { selectIsLoaded } from '../store/selectors/depreciation.selectors';
import * as DepreciationActions from '../store/actions/depreciation.actions';

@Injectable({
  providedIn: 'root',
})
export class DepreciationsGuard {
  private store = inject(Store);

  waitForDataToLoad(): Observable<boolean> {
    return this.store.select(selectIsLoaded).pipe(first(Boolean));
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    this.store.dispatch(
      DepreciationActions.load({
        ...route.queryParams,
      })
    );
    return this.waitForDataToLoad();
  }
}
