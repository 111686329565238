import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
  input,
  output
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '@shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { ThemePalette } from '@angular/material/core';

import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { Animations } from '@shared/animations';
import { LanguageNamePipe } from '@shared/global-pipes/language.pipe';
import { TrBadgeComponent } from '@shared/components/tr-badge/tr-badge.component';
import { TrBreadcrumbComponent } from '@shared/modules/tr-breadcrumbs';
import { selectLanguages } from '@store/selectors/util.selectors';
import { ICompany } from '@domains/company';
import {
  UkuIntegrationStatusComponent,
  UserHasUkuAccessDirective,
} from '@domains/integration/features/uku';

import { HeaderLabel, HeaderMenuItem, LayoutAction, LayoutActionType } from '../../types';
import { CompanyContractStatus } from './components/company-contract-status-indicator';
import { EInvoiceContractStatus } from './components/e-invoice-contract-status-indicator';
import { ClosingPeriodStatus } from './components/company-closing-period-indicator';

@Component({
  selector: 'app-main-layout-header',
  imports: [
    CommonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    SharedModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatDividerModule,
    TrBreadcrumbComponent,
    TrBadgeComponent,
    NgOptimizedImage,
    LanguageNamePipe,
    CompanyContractStatus,
    ClosingPeriodStatus,
    EInvoiceContractStatus,
    UkuIntegrationStatusComponent,
    UserHasUkuAccessDirective,
  ],
  templateUrl: './main-layout-header.component.html',
  styleUrls: ['./main-layout-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.fadeSlideOutIn],
})
export class MainLayoutHeaderComponent implements OnInit {
  private store = inject(Store);
  readonly translate = inject(TranslateService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  readonly menuItems = input<HeaderMenuItem[]>(undefined);

  readonly isViewerActive = input<boolean>(undefined);

  readonly theme = input<ThemePalette>(undefined);

  readonly showCompanyInfo = input<boolean>(undefined);

  readonly sidebarMinimized = input<boolean>(undefined);

  readonly label = input<HeaderLabel>(null);

  readonly selectedLanguage = input<string>(undefined);

  readonly selectedCompany = input<ICompany | null>(null);

  readonly action = output<LayoutAction>();

  animationDisabled = true;

  languages$ = this.store.pipe(select(selectLanguages));

  ngOnInit(): void {
    this.animationDisabled = false;
  }

  openViewer() {
    this.handleAction({
      type: LayoutActionType.ShowFileViewer,
    });
  }

  handleAction(action: LayoutAction) {
    this.action.emit(action);
  }

  navigateTo(url: string) {
    this.action.emit({
      type: LayoutActionType.Navigate,
      payload: {
        url,
      },
    });
  }

  switchCompany() {
    this.action.emit({
      type: LayoutActionType.SwitchCompany,
    });
  }

  updateCompanyInfo(uuid: string): void {
    this.action.emit({
      type: LayoutActionType.UpdateCompanyInfo,
      payload: { uuid },
    });
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    this.changeDetectorRef.detectChanges();
  }
}
