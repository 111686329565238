import { Injectable, inject } from '@angular/core';
import { UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { Actions } from '@ngrx/effects';

import { PermissionsService } from '../_api/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionsGuard {
  private permissions = inject(PermissionsService);
  private actions = inject(Actions);


  canActivate(): Observable<boolean | UrlTree> {
    return this.permissions.accessGuard('transaction');
  }
}
