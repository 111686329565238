import { CacheStorage } from '@shared/cache/cache.storage';
import { CacheEntry } from '@shared/cache/types';
import { AppConfigs } from '@src/configs';

import CACHE_MAX_AGE = AppConfigs.CACHE_MAX_AGE;

export class BaseCacheStorage implements CacheStorage {
  private _cache: Record<string, CacheEntry<unknown>>;
  private _requestContext: Record<string, string[]>;

  constructor(initialCache = {}, requestContext = {}) {
    this._cache = initialCache || {};
    this._requestContext = requestContext || {};
  }

  getCache() {
    return this._cache;
  }

  getRequestContextCache() {
    return this._requestContext;
  }

  get<T = unknown>(key: string): T | null {
    const item = this._cache[key];
    if (item && (item?.expiry || 0) > Date.now()) {
      return item.value as T;
    } else {
      this.remove(key);
      return null;
    }
  }

  set<T>(key: string, value: T, expiration?: number): void {
    const expiry = Date.now() + expiration || CACHE_MAX_AGE;
    this._cache[key] = { value, expiry };
  }

  remove(key: string): void {
    delete this._cache[key];
  }

  clear(): void {
    this._cache = {};
  }

  clearByContext(context: string | string[]): void {
    const keys = this.getRequestContext(context);
    if (keys) {
      keys.forEach((key: string) => {
        this.remove(key);
      });
      this.removeRequestContext(context);
    }
  }

  getRequestContext<T>(key: string | string[]): string[] {
    const contexts = Array.isArray(key) ? key : [key];
    return contexts.reduce((acc: string[], context: string) => {
      const keys = this._requestContext[context];
      if (keys && keys.length) {
        acc.push(...keys);
      }
      return acc;
    }, []);
  }

  removeRequestContext(key: string | string[]): void {
    const contexts = Array.isArray(key) ? key : [key];
    contexts.forEach((context: string) => {
      delete this._requestContext[context];
    });
  }

  setRequestContext(key: string | string[], value: string): void {
    const contexts = Array.isArray(key) ? key : [key];
    contexts.forEach((context: string) => {
      const keys = this.getRequestContext(context);
      keys.push(value);
      this._requestContext[context] = keys;
    });
  }

  clearRequestContext(): void {
    this._requestContext = {};
  }
}
