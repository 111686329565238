<h2 mat-dialog-title>{{ 'dialog.create_password.title' | translate }}</h2>
<div mat-dialog-content>
  <div class="create-password-form" [formGroup]="createPasswordForm">
    <h3
      class="g-column-full"
      [innerHTML]="'dialog.create_password.for' | translate: {email: data.email} "
    ></h3>
    <mat-form-field>
      <mat-label>{{ 'settings.new_pass' | translate }}</mat-label>
      <input
        [type]="hide ? 'password' : 'text'"
        matInput
        formControlName="password"
        placeholder="{{ 'settings.new_pass' | translate }}"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'settings.confirm_new_pass' | translate }}</mat-label>
      <input
        [type]="hide1 ? 'password' : 'text'"
        type="password"
        matInput
        formControlName="confirmPassword"
        placeholder="{{ 'text.repeat_pass' | translate }}"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hide1 = !hide1"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    @if (createPasswordForm.hasError('mismatch')) {
    <mat-error class="confirm-password-error">
      {{ 'error.password.mismatch' | translate }}
    </mat-error>
    }
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button color="primary" (click)="createPassword()">
    {{'system.save' | translate}}
  </button>
</div>
