import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { FixedAssetService } from '@api/fixed-asset.service';
import * as FixedAssetActions from '@store/actions/fixed-asset.actions';

@Injectable({
  providedIn: 'root',
})
export class FixedAssetGuard {
  private router = inject(Router);
  private store = inject(Store);
  private fixedAssetService = inject(FixedAssetService);


  hasEntityInApi(uuid: string): Observable<boolean | UrlTree> {
    return this.fixedAssetService.getById(uuid).pipe(
      map((payload) => FixedAssetActions.loadByIdSuccess({ payload })),
      tap((action) => this.store.dispatch(action)),
      map((entity) => !!entity),
      catchError(() => of(this.router.createUrlTree(['/404'])))
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const uuid = route.paramMap.get('uuid');
    return this.hasEntityInApi(uuid);
  }
}
