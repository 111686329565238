import { createReducer, on } from '@ngrx/store';
import {
  generateBalanceSheet,
  generateBalanceSheetSuccess,
  generateGeneralLedger,
  generateGeneralLedgerSuccess,
  generateProfitAndLoss,
  generateProfitAndLossSuccess,
  financialStatementReset,
  downloadStatementOfAccountsPdf,
  downloadStatementOfAccountsPdfSuccess,
  resetGeneralLedger,
} from '../actions/financial-statements.actions';
import { LoadingState, Configs } from '../../_shared/configs.model';
import { FinancialStatement } from '../models/financial-statement.model';
import { downloadStatementOfAccountsPdfFailure } from '../actions/financial-statements.actions';

export const featureKey = 'financial-statements';

export interface State {
  balanceSheet: any;
  generalLedger: FinancialStatement.GeneralLedger[];
  profitAndLoss: any;
  callState: Configs.CallState;
  downloadPdfInProgress: boolean;
}

export const initialState: State = {
  balanceSheet: null,
  generalLedger: null,
  profitAndLoss: null,
  callState: LoadingState.INIT,
  downloadPdfInProgress: false,
};

export const reducer = createReducer(
  initialState,
  on(generateBalanceSheet, generateGeneralLedger, generateProfitAndLoss, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(generateBalanceSheetSuccess, (state, { payload }) => ({
    ...state,
    callState: LoadingState.LOADED,
    balanceSheet: payload,
  })),
  on(generateGeneralLedgerSuccess, (state, { payload }) => ({
    ...state,
    callState: LoadingState.LOADED,
    generalLedger: payload,
  })),
  on(resetGeneralLedger, (state) => ({
    ...state,
    ...initialState,
  })),
  on(generateProfitAndLossSuccess, (state, { payload }) => ({
    ...state,
    callState: LoadingState.LOADED,
    profitAndLoss: payload,
  })),
  on(downloadStatementOfAccountsPdf, (state) => ({
    ...state,
    downloadPdfInProgress: true,
  })),
  on(downloadStatementOfAccountsPdfSuccess, (state) => ({
    ...state,
    downloadPdfInProgress: false,
  })),
  on(downloadStatementOfAccountsPdfFailure, (state) => ({
    ...state,
    downloadPdfInProgress: false,
  })),
  on(financialStatementReset, () => initialState)
);

export const getBalanceSheet = (state: State) => state.balanceSheet;
export const getGeneralLedger = (state: State) => state.generalLedger;
export const getProfitAndLoss = (state: State) => state.profitAndLoss;
export const getDownloadStatemenPdfInProgress = (state: State) => state.downloadPdfInProgress;
