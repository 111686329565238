import { Injectable } from '@angular/core';
import { CacheStorage } from '@shared/cache/cache.storage';
import { BaseCacheStorage } from '@shared/cache/base-cache.storage';

@Injectable()
export class RefreshCacheStorage extends BaseCacheStorage implements CacheStorage {
  constructor() {
    super();
  }
}
