import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'tr-badge',
  template: `
    @let vm =
      {
        text: text(),
        theme: theme(),
        localize: localize(),
        containerClass: containerClass(),
      };
    <div
      class="tr-label"
      [ngClass]="[vm.containerClass || '', vm.theme ? 'label-' + vm.theme : '']"
    >
      @if (vm.localize) {
        {{ vm.text | translate | titlecase }}
      } @else {
        {{ vm.text | titlecase }}
      }
    </div>
  `,
  styles: [
    `
      :host {
        display: inline-flex;
      }
    `,
  ],
  imports: [TranslateModule, NgClass, TitleCasePipe],
})
export class TrBadgeComponent {
  readonly text = input<string>(undefined);
  readonly localize = input<boolean>(undefined);
  readonly containerClass = input<string>(undefined);
  readonly theme = input<'primary' | 'accent' | 'warn'>('primary');
}
