import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { take, filter, switchMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { selectFinancialEntriesIsLoaded } from '@store/selectors/finance.selectors';
import { loadFinances } from '@store/actions/finance.actions';
import { PermissionsService } from '@api/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class FinancialRegistryGuard {
  private store = inject(Store);
  private permissions = inject(PermissionsService);


  waitForDataToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(selectFinancialEntriesIsLoaded),
      filter((loaded) => loaded),
      take(1)
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    this.store.dispatch(
      loadFinances({
        searchKeyword: route.queryParamMap.get('searchKeyword'),
      })
    );
    return this.waitForDataToLoad().pipe(
      switchMap(() => this.permissions.accessGuard('financial_entry'))
    );
  }
}
