import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LayoutAction, LayoutActionType } from '@app/_layouts';

import { Auth } from '@models/auth.model';
import { ICompany } from '@domains/company';
import { Animations } from '@shared/animations';
import { FirstLettersPipe } from '@shared/global-pipes/first-letters.pipe';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-main-layout-sidenav-header',
  templateUrl: './main-layout-sidenav-header.component.html',
  styleUrls: ['./main-layout-sidenav-header.component.scss'],
  imports: [
    CommonModule,
    FirstLettersPipe,
    MatButtonModule,
    MatTooltipModule,
    SharedModule,
    MatProgressSpinnerModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.fadeSlideInOut],
  providers: [FirstLettersPipe],
})
export class MainLayoutSidenavHeaderComponent {
  private firstLetterPipe = inject(FirstLettersPipe);

  readonly company = input<ICompany>(undefined);

  readonly authUser = input<Auth.User>(undefined);

  readonly showCompanyInfo = input<boolean>(undefined);

  readonly sidebarMinimized = input<boolean>(undefined);

  readonly action = output<LayoutAction>();

  getAuthUserLetter() {
    const displayName = this.getAuthUserDisplayName();
    const chunks = displayName.split(' ');
    return chunks.length > 1
      ? this.firstLetterPipe.transform(chunks[0], 1) + this.firstLetterPipe.transform(chunks[1], 1)
      : this.firstLetterPipe.transform(chunks[0], 2);
  }

  getAuthUserDisplayName() {
    const { firstName, lastName, email } = this.authUser();
    return firstName && lastName
      ? `${firstName} ${lastName}`
      : firstName || lastName || email || 'unknown';
  }

  switchCompany() {
    this.action.emit({
      type: LayoutActionType.SwitchCompany,
    });
  }

  updateCompanyInfo(uuid: string): void {
    this.action.emit({
      type: LayoutActionType.UpdateCompanyInfo,
      payload: { uuid },
    });
  }
}
