@let vm =
  {
    theme: theme(),
    label: label(),
    menuItems: menuItems(),
    isViewerActive: isViewerActive(),
    showCompanyInfo: showCompanyInfo(),
    selectedCompany: selectedCompany(),
    sidebarMinimized: sidebarMinimized(),
  };

<mat-toolbar [color]="vm.theme" class="custom-toolbar">
  <div class="d-flex d-flex-center no-print">
    @if (vm.showCompanyInfo && vm.selectedCompany) {
      <div class="no-overflow">
        @if (vm.sidebarMinimized) {
          <div @fadeSlideOutIn class="d-flex d-flex-center">
            <button
              class="small-icon-btn mr-2"
              (click)="updateCompanyInfo(vm.selectedCompany.uuid)"
              mat-flat-button
            >
              <i class="tr icon-pencil"></i>
            </button>
            <a
              [matTooltip]="vm.selectedCompany.name"
              (click)="switchCompany()"
              class="company-selector tr-breadcrumb-item tr-breadcrumb-link"
              >{{ vm.selectedCompany.name }}</a
            >
            <i class="tr icon-arrow-right px-2"></i>
          </div>
        }
      </div>
    }

    <div class="left-toolbar">
      <tr-breadcrumb [separator]="breadcrumbSeparator" [autoGenerate]="false"></tr-breadcrumb>

      <ng-template #breadcrumbSeparator>
        <i class="tr icon-arrow-right"></i>
      </ng-template>

      @if (vm.label; as label) {
        <tr-badge
          [containerClass]="label.class"
          [text]="label.title"
          [theme]="label.theme"
          [localize]="label.translate"
        />
      }
    </div>
  </div>
  <div class="logo no-print" [class.color-inverted]="vm.theme">
    <img
      priority="high"
      alt="CHK Logo"
      ngSrc="assets/images/chk-logo.svg"
      class="company-logo"
      height="62"
      width="97"
    />
  </div>

  <div class="rigth-toolbar no-print">
    <div class="right-side d-flex f-center-horizontal haf-gap pl-4">
      @if (vm.selectedCompany; as company) {
        <company-closing-period-status [company]="company"></company-closing-period-status>
        <uku-integration-status *userHasUkuAccess [companyID]="company.id" />
        <company-contract-status [company]="company"></company-contract-status>
        <e-invoice-contract-status [company]="company"></e-invoice-contract-status>
      }

      <button
        class="eye-btn"
        [matTooltip]="
          vm.isViewerActive
            ? ('text.active_viewer_tooltip' | translate)
            : ('text.not_active_viewer_tooltip' | translate)
        "
        (click)="openViewer()"
        [disabled]="!vm.isViewerActive"
        [class.active]="vm.isViewerActive"
      >
        <i class="tr icon-visibility text-primary"></i>
      </button>

      <button mat-button [matMenuTriggerFor]="lang">
        {{ translate.currentLang.split('-')[0] | uppercase }}
        <i class="tr icon-caret-down icon-sm"></i>
      </button>
      <mat-menu #lang="matMenu">
        @for (lang of languages$ | async; track lang) {
          <button (click)="changeLang(lang.code)" mat-menu-item>
            {{ lang.code | languageName | async | uppercase }}
          </button>
        }
      </mat-menu>

      <button
        (click)="navigateTo('dashboard')"
        [matTooltip]="'core.dashboard' | translate"
        class="with-icon"
        mat-icon-button
      >
        <i class="tr icon-list-items"></i>
      </button>

      <button
        class="with-icon"
        mat-icon-button
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="menu"
      >
        <i class="tr icon-user icon-lg"></i>
      </button>

      <mat-menu #menu="matMenu">
        @for (item of vm.menuItems; track item) {
          <button style="width: 180px" (click)="handleAction(item.action)" mat-menu-item>
            <span class="d-flex f-center-horizontal">
              @if (item.icon) {
                <i class="tr {{ item.icon }}"></i>
              }
              <span class="ml-2">
                {{ item.translate ? (item.label | translate) : item.label }}
              </span>
            </span>
          </button>
          @if (item.groupEnd) {
            <mat-divider />
          }
        }
      </mat-menu>
    </div>
  </div>

  @if (vm.selectedCompany) {
    <div class="print-only">
      <div class="d-flex">
        <span>{{ vm.selectedCompany.name }}</span>
        <span class="px-2">/</span>
        <tr-breadcrumb
          [separator]="breadcrumbSeparator"
          [autoGenerate]="false"
          [showOnlyLast]="true"
        ></tr-breadcrumb>
      </div>
    </div>
  }
</mat-toolbar>
