import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { filter, map, take, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as VatReportActions from '../store/actions/vat-report.actions';
import * as VatReportSelectors from '../store/selectors/vat-report.selectors';

@Injectable({
  providedIn: 'root',
})
export class VatReportDetailResolver {
  private store = inject(Store);


  resolve(snapshot: ActivatedRouteSnapshot) {
    const uuid = snapshot.paramMap.get('uuid');
    this.store.dispatch(VatReportActions.loadById({ uuid }));

    return this.store.pipe(select(VatReportSelectors.selectById({ uuid }))).pipe(
      filter((report) => report?.lines),
      take(1)
    );
  }
}
