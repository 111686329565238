import { CacheStorage } from './cache.storage';
import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE } from '@shared/tokens';
import { AppConfigs } from '@src/configs';
import { BaseCacheStorage } from './base-cache.storage';

const STORAGE_KEY = AppConfigs.CACHE_STORAGE_KEY;
const STORAGE_CONTEXT_KEY = `${STORAGE_KEY}-context`;

@Injectable()
export class SessionCacheStorage extends BaseCacheStorage implements CacheStorage {
  constructor(@Inject(SESSION_STORAGE) private _storage: Storage) {
    let cache: any = {};
    let requestContext: any = {};

    try {
      cache = JSON.parse(_storage.getItem(STORAGE_KEY)) || {};
      requestContext = JSON.parse(_storage.getItem(STORAGE_CONTEXT_KEY)) || {};
    } catch (e) {}

    super(cache, requestContext);
  }

  override set<T>(key: string, value: T, expiration?: number): void {
    super.set(key, value, expiration);
    this._saveCache();
  }

  override remove(key: string): void {
    super.remove(key);
    this._saveCache();
  }

  override clear(): void {
    super.clear();
    this._saveCache();
  }

  override clearByContext(context: string | string[]): void {
    super.clearByContext(context);
    this._saveCache();
    this._saveRequestContext();
  }

  override setRequestContext(key: string | string[], value: string) {
    super.setRequestContext(key, value);
    this._saveRequestContext();
  }

  override removeRequestContext(key: string | string[]): void {
    super.removeRequestContext(key);
    this._saveRequestContext();
  }

  override clearRequestContext(): void {
    super.clearRequestContext();
    this._saveRequestContext();
  }

  private _saveCache(): void {
    try {
      this._storage.setItem(STORAGE_KEY, JSON.stringify(super.getCache()));
    } catch (e) {
      this._storage.removeItem(STORAGE_KEY);
    }
  }

  private _saveRequestContext(): void {
    try {
      this._storage.setItem(STORAGE_CONTEXT_KEY, JSON.stringify(super.getRequestContextCache()));
    } catch (e) {
      this._storage.removeItem(STORAGE_CONTEXT_KEY);
    }
  }
}
