@if (data) {
  <div class="head">
    {{ data.documentName }}
  </div>
  @if (safeUrl) {
    @if (isImg) {
      <tr-image-viewer [src]="[imageUrl]"></tr-image-viewer>
    } @else {
      <iframe [src]="safeUrl"></iframe>
    }
  }
} @else {
  <div class="empty">
    <h4>{{ 'text.self_viewer_text' | translate }}</h4>
  </div>
}
