import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { take, filter, switchMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { selectIsLoaded } from '../store/selectors/person.selectors';
import { load } from '../store/actions/person.actions';
import { PermissionsService } from '../_api/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class PersonsGuard {
  private store = inject(Store);
  private permissions = inject(PermissionsService);


  waitForDataToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(selectIsLoaded),
      filter((loaded) => loaded),
      take(1)
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    this.store.dispatch(
      load({
        searchKeyword: route.queryParamMap.get('searchKeyword'),
      })
    );
    return this.waitForDataToLoad().pipe(switchMap(() => this.permissions.accessGuard('person')));
  }
}
