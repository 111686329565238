import { Directive } from '@angular/core';
import { CdkAccordion } from '@angular/cdk/accordion';

@Directive({
  selector: '[trigon-accordion]',
  exportAs: 'trigonAccordion',
  inputs: ['multi'],
  host: {
    class: 'trigon-accordion',
    // Class binding which is only used by the test harness as there is no other
    // way for the harness to detect if multiple panel support is enabled.
    '[class.trigon-accordion-multi]': 'this.multi',
  },
  standalone: false,
})
export class TrigonAccordion extends CdkAccordion {}
