import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  inject,
  viewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { filter, startWith, takeUntil, map } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@env/environment';
import { CompanyPopupsService } from '@domains/company';
import { selectLanguages } from '@store/selectors/util.selectors';
import { logout } from '@store/actions/auth.actions';
import { ConfirmPopupComponent } from '@shared/popups/confirm-popup';

import { LayoutService } from '@app/_layouts/layout.service';
import { LayoutAction, LayoutActionType } from '@app/_layouts';

import { LocalStorageService } from '../self-viewer/local-storage.service';
import { CoreRoutePathEnum } from '@app/_containers/core-container';
import { DomService } from '@shared/global-services/dom.service';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  animations: [
    trigger('inContentAnimation', [
      transition(':leave', [
        style({ opacity: '*' }),
        animate('200ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
  standalone: false,
})
export class CoreComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private location = inject(Location);
  private domService = inject(DomService);
  private translate = inject(TranslateService);
  private layoutService = inject(LayoutService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private localStorageService = inject(LocalStorageService);
  private companyPopupsService = inject(CompanyPopupsService);

  readonly sidenav = viewChild<ElementRef>('sidenav');

  private destroyed$ = new Subject<void>();

  user: any;
  production: boolean = !environment.production;
  languages$ = this.store.pipe(select(selectLanguages));

  loading = true;

  constructor() {
    this.localStorageService.storageChange$
      .pipe(
        startWith(this.localStorageService.getStorageItem('isOpen')),
        map(() => this.localStorageService.getStorageItem('isOpen'))
      )
      .subscribe((isOpen) => {
        this.layoutService.setFileViewerTabActiveState(isOpen);
      });
  }

  ngOnInit() {
    this.layoutService.actions$.pipe(takeUntil(this.destroyed$)).subscribe((action) => {
      this.handleLayoutActions(action);
    });

    this.hideInitialLoader();
  }

  private hideInitialLoader() {
    const loader = this.domService.getElement('.tr-initial-loader');
    this.domService.hideElementWithTransition(loader, 300, (el) => {
      const styleElement = this.domService.getElement('#trLoaderStyle');
      this.domService.removeElement(el);
      this.domService.removeElement(styleElement);
    });
  }

  private handleLayoutActions(action: LayoutAction): void {
    switch (action.type) {
      case LayoutActionType.ChangeTheme: {
        // this.layoutService.setTheme(action.payload);
        break;
      }
      case LayoutActionType.ChangeLanguage: {
        this.changeLang(action.payload.lang);
        break;
      }

      case LayoutActionType.ShowFileViewer: {
        this.openViewer();
        break;
      }

      case LayoutActionType.UpdateCompanyInfo: {
        this.editCompany(action.payload.uuid);
        break;
      }

      case LayoutActionType.SwitchCompany: {
        this.searchCompanies();
        break;
      }

      case LayoutActionType.Navigate: {
        this.navigateTo(action.payload.url);
        break;
      }
      case LayoutActionType.SignOut: {
        this.signOut();
        break;
      }
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openViewer() {
    this.dialog
      .open(ConfirmPopupComponent, {
        width: '480px',
        data: {
          title: this.translate.instant('text.viewer_confirm_viewer_title'),
          body: this.translate.instant('text.viewer_confirm_viewer_confirm'),
        },
      })
      .afterClosed()
      .pipe(filter((result) => !!result))
      .subscribe(() => {
        const url = this.getCurrentDomainUrl();
        window.open(`${url}/doc-viewer`, '_blank');
        this.localStorageService.setStorageItem('isOpen', true);
      });
  }

  getCurrentDomainUrl(): string {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const port = window.location.port;

    // If there's a port, include it in the domain URL.
    const domainUrl = port ? `${protocol}//${hostname}:${port}` : `${protocol}//${hostname}`;
    return domainUrl;
  }

  editCompany(uuid) {
    this.router.navigate(['', uuid, CoreRoutePathEnum.CompanySettings]);
  }

  back() {
    this.location.back();
  }

  changeLang(lang) {
    this.translate.use(lang);
    this.changeDetectorRef.detectChanges();
  }

  navigateTo(uuid) {
    this.router.navigate(['', uuid]);
  }

  searchCompanies(): void {
    this.companyPopupsService.showCompanySelectionPopup().subscribe((uuid) => {
      this.router.navigate(['', uuid]);
    });
  }

  signOut() {
    this.store.dispatch(logout());
  }
}
