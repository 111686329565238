import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
  input,
  effect,
  output
} from '@angular/core';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { matExpansionAnimations, MatExpansionPanelState } from '@angular/material/expansion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { TrigonAccordion } from './trigon-accordion';

@Component({
  selector: 'app-trigon-accordion-item',
  templateUrl: './trigon-accordion-item.component.html',
  styleUrls: ['./trigon-accordion-item.component.scss'],
  providers: [UniqueSelectionDispatcher],
  animations: [matExpansionAnimations.bodyExpansion, matExpansionAnimations.indicatorRotate],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TrigonAccordionComponentItem extends CdkAccordionItem implements OnInit {
  readonly sidebarMinimized = input<boolean>(false);

  animationIsDisabled = true;

  readonly toggled = output<void>();

  constructor() {
    const accordion = inject(TrigonAccordion, { optional: true, skipSelf: true });
    const _changeDetectorRef = inject(ChangeDetectorRef);
    const _expansionDispatcher = inject(UniqueSelectionDispatcher);

    super(accordion, _changeDetectorRef, _expansionDispatcher);
    this.accordion = accordion;

    effect(() => {
      if (this.sidebarMinimized()) {
        this.close(); // Manually close the accordion item
      }
    });
  }

  get expandedState(): MatExpansionPanelState {
    return this.expanded ? 'expanded' : 'collapsed';
  }

  override toggle(): void {
    super.toggle();
    this.toggled.emit();
  }

  ngOnInit(): void {
    // This is a hack to prevent the accordion from opening when the sidebar is minimized
    setTimeout(() => {
      this.animationIsDisabled = false;
    }, 100);
  }
}
