import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  input,
  output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ThemePalette } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule } from '@ngx-translate/core';

import { Auth } from '@models/auth.model';
import { ICompany } from '@domains/company';
import { Animations } from '@shared/animations';
import { SharedModule } from '@shared/shared.module';
import { PermissionsModule } from '@app/permissions/permissions.module';
import { NgAccessDirective } from '@app/permissions/ng-access.directive';
import { LayoutAction, LayoutActionType, SidebarMenuItem } from '@app/_layouts';
import { TrigonAccordionModule } from '@shared/trigon-accordion/trigon-accordion.module';

import { MainLayoutSidenavHeaderComponent } from './main-layout-sidenav-header/main-layout-sidenav-header.component';

@Component({
  selector: 'app-main-layout-sidenav',
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    TranslateModule,
    TrigonAccordionModule,
    MatDividerModule,
    MatListModule,
    PermissionsModule,
    SharedModule,
    MatTooltipModule,
    NgAccessDirective,
    MainLayoutSidenavHeaderComponent,
  ],
  templateUrl: './main-layout-sidenav.component.html',
  styleUrls: ['./main-layout-sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.fade],
})
export class MainLayoutSidenavComponent {
  readonly menuItems = input<SidebarMenuItem[]>([]);

  readonly theme = input<ThemePalette>(undefined);

  readonly basePath = input<string | null>(undefined);

  readonly sidebarMinimized = input<boolean>(undefined);

  readonly company = input<ICompany | null>(undefined);

  readonly authUser = input<Auth.User | null>(undefined);

  readonly showCompanyInfo = input<boolean>(undefined);

  readonly sidebarToggle = output<void>();

  readonly accordionItemToggle = output<void>();

  readonly action = output<LayoutAction>();

  @HostBinding('class.minimized') get minimized(): boolean {
    return this.sidebarMinimized();
  }

  constructor() {}

  public getRouteLink(item: SidebarMenuItem): string {
    const basePath = this.basePath();
    return basePath ? `${basePath}/${item.link}` : item.link;
  }

  maximizeIfClosed(event: Event): void {
    event.stopImmediatePropagation();
    if (this.sidebarMinimized()) {
      this.sidebarToggle.emit();
    }
  }

  onAccordionItemToggle(): void {
    this.accordionItemToggle.emit();
  }

  onToggleSidebar(event: Event): void {
    event.stopPropagation();
    this.sidebarToggle.emit();
  }

  switchCompany() {
    this.action.emit({
      type: LayoutActionType.SwitchCompany,
    });
  }

  updateCompanyInfo(uuid: string): void {
    this.action.emit({
      type: LayoutActionType.UpdateCompanyInfo,
      payload: { uuid },
    });
  }
}
