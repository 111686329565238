<aside (click)="maximizeIfClosed($event)" [class.is-collapsed]="sidebarMinimized()">
  <div class="toggle-btn-content">
    <button mat-icon-button class="menu-collapse" (click)="onToggleSidebar($event)">
      @if (sidebarMinimized()) {
        <i class="tr icon-arrow-right"></i>
      }
      @if (!sidebarMinimized()) {
        <i class="tr icon-arrow-left"></i>
      }
    </button>
  </div>

  <app-main-layout-sidenav-header
    [company]="company()"
    [authUser]="authUser()"
    [showCompanyInfo]="showCompanyInfo()"
    [sidebarMinimized]="sidebarMinimized()"
    (action)="action.emit($event)"
  ></app-main-layout-sidenav-header>

  <nav class="sidebar-nav" [class.overflow-hidden]="sidebarMinimized()">
    <ul trigon-accordion multi="true" class="main-nav list-unstyled">
      @for (item of menuItems(); track item.label) {
        <ng-container
          [ngTemplateOutlet]="
            item.children && item.children.length ? rootItemWithSubItems : rootItem
          "
          [ngTemplateOutletContext]="{ $implicit: item }"
        >
        </ng-container>
      }

      <!-- <li class="menu-logo-mini">
        <div class="menu-logo-mini-img-container">
          <img src="assets/images/trigon-logo-menu-mini.svg" />
        </div>
      </li>

      <div class="menu-logo d-flex">
        <img src="assets/images/trigon-logo-menu.svg" alt="menu-logo" />
      </div> -->
    </ul>
  </nav>
</aside>

<ng-template #rootItem let-item>
  <li
    [@fade]="item"
    class="no-child"
    [routerLink]="getRouteLink(item)"
    routerLinkActive="active-border"
    *ngAccess="item.access"
  >
    <div class="rootItem">
      <i [className]="'tr icon-' + item.icon"></i>
      <ng-container
        [ngTemplateOutlet]="linkTmp"
        [ngTemplateOutletContext]="{ $implicit: item, classes: 'nav-text' }"
      >
      </ng-container>
    </div>
  </li>
  @if (item.groupEnd) {
    <mat-divider></mat-divider>
  }
</ng-template>

<ng-template #rootItemWithSubItems let-item>
  <li *ngAccess="item.access">
    <app-trigon-accordion-item
      [sidebarMinimized]="sidebarMinimized()"
      (toggled)="onAccordionItemToggle()"
    >
      <div class="heading">
        <i [className]="'tr icon-' + item.icon"></i>
        <span class="nav-text">{{ item.label | translate }}</span>
      </div>
      <div class="content">
        <mat-nav-list>
          @for (child of item.children; track child.label) {
            <a
              *ngAccess="child.access"
              class="cursor-pointer"
              [routerLink]="getRouteLink(child)"
              mat-list-item
              routerLinkActive="active-border"
            >
              {{ child.label | translate }}
            </a>
          }
        </mat-nav-list>
      </div>
    </app-trigon-accordion-item>
  </li>
  @if (item.groupEnd) {
    <mat-divider></mat-divider>
  }
</ng-template>

<ng-template #linkTmp let-item let-classes="classes">
  <a
    *ngAccess="item.access"
    class="cursor-pointer {{ classes }}"
    [routerLink]="getRouteLink(item)"
    routerLinkActive="active-border"
  >
    {{ item.label | translate }}
  </a>
</ng-template>
