import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule } from '@ngx-translate/core';

import { CURRENT_COMPANY_STORE, CompanyPopupsService, ICompany } from '@domains/company';
import { TrDatePipe } from '@shared/global-pipes/tr-date.pipe';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'company-closing-period-status',
  imports: [TranslateModule, MatButtonModule, MatTooltipModule, SharedModule],
  template: `@if (company(); as company) {
    <button (click)="showContractStatusHistory()" class="closing-period-btn no-animate">
      <i class="tr icon-lock"></i>
      @if (company.periodEnd) {
        <i class="tr icon-arrow-right"></i>
        <p class="closing-period">{{ trDatePipe.transform(company.periodEnd) }}</p>
      } @else {
        <i class="tr icon-plus"></i>
      }
    </button>
  }`,
  styles: [
    `
      .closing-period-btn {
        align-items: center;
        justify-content: center;
        background: none;
        cursor: pointer;
        display: flex;
        gap: 6px;
        height: 32px;
        margin-right: 14px;
        width: unset;
        border: 1px solid black;
        border-radius: 4px;
      }

      .closing-period {
        margin: 0;
        margin-top: 2px;
        font-size: 12px;
      }

      .icon-arrow-right {
        color: black !important;
        font-size: 18px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClosingPeriodStatus {
  private readonly companyStore = inject(CURRENT_COMPANY_STORE);
  private readonly popupsService = inject(CompanyPopupsService);

  readonly company = input<ICompany>(null);

  readonly trDatePipe = new TrDatePipe();

  showContractStatusHistory(): void {
    this.popupsService.showClosingPeriodPopup(this.company()).subscribe((res) => {
      if (res.actionHappened && res.updatedDate) {
        this.companyStore.updatePeriodEnd(res.updatedDate);
      }
    });
  }
}
