import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { take, filter, switchMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { selectEntryById } from '../store/selectors/finance.selectors';
import { loadEntryByUuid } from '../store/actions/finance.actions';
import { PermissionsService } from '../_api/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class FinancialEntryGuard {
  private store = inject(Store);
  private permissions = inject(PermissionsService);


  waitForDataToLoad(uuid): Observable<boolean> {
    return this.store.pipe(
      select(selectEntryById({ uuid })),
      filter((val: any) => val?.financialEntryLines),
      take(1)
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const uuid = route.paramMap.get('uuid');
    this.store.dispatch(loadEntryByUuid({ uuid }));
    return this.waitForDataToLoad(uuid).pipe(
      switchMap(() => this.permissions.accessGuard('financial_entry'))
    );
  }
}
