import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  input,
  effect,
  untracked,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ICompany } from '@src/app/_domains/company';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoaderOverlayDirective } from '@src/app/_shared/global-directives/loader-overlay.directive';
import { TrDatePipe } from '@src/app/_shared/global-pipes/tr-date.pipe';
import { SharedModule } from '@src/app/_shared/shared.module';
import { EInvoiceContractStatusIndicator } from '@src/app/_domains/company/shared/components/e-invoice-contract-status-indicator';
import {
  EInvoiceContract,
  EInvoiceContractState,
  EInvoiceService,
} from '@src/app/_domains/invoice';
import { filter } from 'rxjs/operators';

type Entity = EInvoiceContract & {
  current?: boolean;
};

@Component({
  selector: 'e-invoice-contract-status',
  imports: [
    TranslateModule,
    EInvoiceContractStatusIndicator,
    MatButtonModule,
    MatTooltipModule,
    LoaderOverlayDirective,
    SharedModule,
  ],
  template: `
    @if (loading) {
      <div [loaderOverlay]="true" loaderIndicatorSize="small">
        <div style="height: 24px; width: 24px"></div>
      </div>
    } @else {
      <button (click)="showContractStatusHistory()" class="contract-status-btn active no-animate">
        <e-invoice-contract-status-indicator
          [status]="currentContract?.currentState || states.TERMINATED"
          [showLabel]="false"
          [iconSize]="'24px'"
        ></e-invoice-contract-status-indicator>
      </button>
    }
  `,
  styles: [
    `
      .contract-status-btn {
        align-items: center;
        justify-content: center;
        background: none;
        cursor: pointer;
        display: flex;
        gap: 6px;
        height: 32px;
        width: unset;
        border: none;

        &.bordered {
          border: 1px solid var(--tr-orange);
          border-radius: 4px;
        }
      }

      .icon-rotate {
        color: var(--tr-orange) !important;
        font-size: 24px;
      }

      .icon-arrow-right {
        color: black !important;
        font-size: 18px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EInvoiceContractStatus {
  private eInoviceService = inject(EInvoiceService);
  private chd = inject(ChangeDetectorRef);

  readonly company = input<ICompany>(undefined);
  loading: boolean = true;
  currentContract: Entity;
  states = EInvoiceContractState;
  trDatePipe = new TrDatePipe();

  private fetchContractsHistory(company: ICompany): void {
    if (company) {
      this.loading = true;
      this.eInoviceService.getContractById(company.id).subscribe((contracts) => {
        this.currentContract = contracts.find((item) => item.provider === 'RIK');

        this.loading = false;
        this.chd.detectChanges();
      });
    }
  }

  reset() {
    this.currentContract = null;
  }

  showContractStatusHistory(): void {
    const company = this.company();
    this.eInoviceService
      .showStatusesHistoryPopup(company)
      .pipe(filter((r) => !!r))
      .subscribe(() => this.fetchContractsHistory(company));
  }

  constructor() {
    effect(() => {
      const company = this.company();
      untracked(() => {
        this.reset();
        this.fetchContractsHistory(company);
      });
    });
  }
}
