import { Injectable, inject } from '@angular/core';
import { UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { PermissionsService } from '../_api/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class PurchaseLedgerGuard {
  private permissions = inject(PermissionsService);


  canActivate(): Observable<boolean | UrlTree> {
    return this.permissions.accessGuard('purchase_invoice');
  }
}
