import { createAction, props } from '@ngrx/store';

export const generateBalanceSheet = createAction(
  '[Fin Statements/API] Get Balance Sheet',
  props<{ from: string; to: string }>()
);

export const generateBalanceSheetSuccess = createAction(
  '[Fin Statements/API] Get Balance Sheet Success',
  props<{ payload: any }>()
);

export const generateBalanceSheetFailure = createAction(
  '[Fin Statements/API] Get Balance Sheet Failure',
  props<{ error: any }>()
);

export const generateGeneralLedger = createAction(
  '[Fin Statements/API] Get General Ledger',
  props<{
    payload: {
      from: string;
      to?: string;
      accountFromUUID?: string;
      accountToUUID?: string;
      vatRateUUID?: string;
      expenseAndRevenueTurnover?: boolean;
      daily?: boolean;
    };
  }>()
);

export const generateGeneralLedgerSuccess = createAction(
  '[Fin Statements/API] Get General Ledger Success',
  props<{ payload: any }>()
);

export const generateGeneralLedgerFailure = createAction(
  '[Fin Statements/API] Get General Ledger Failure',
  props<{ error: any }>()
);

export const resetGeneralLedger = createAction('[Fin Statements/API] Reset General Ledger');

export const generateProfitAndLoss = createAction(
  '[Fin Statements/API] Get Profit And Loss',
  props<{ from: string; to: string }>()
);

export const generateProfitAndLossSuccess = createAction(
  '[Fin Statements/API] Get Profit And Loss Success',
  props<{ payload: any }>()
);

export const generateProfitAndLossFailure = createAction(
  '[Fin Statements/API] Get Profit And Loss Failure',
  props<{ error: any }>()
);

export const financialStatementReset = createAction(
  '[Fin Statements/API] Financial Statement reset'
);

export const downloadPlXlsx = createAction(
  '[Fin Statements/API] Download Profit And Loss XLSX',
  props<{ from: string; to: string; detailed: boolean; showUnused: boolean; labels: string[] }>()
);

export const downloadBsXlsx = createAction(
  '[Fin Statements/API] Download Balance Sheet XLSX',
  props<{ from: string; to: string; detailed: boolean; showUnused: boolean }>()
);

export const downloadGlCsv = createAction(
  '[Fin Statements/API] Download General Ledger CSV',
  props<{
    payload: {
      from: string;
      to?: string;
      accountFromUUID?: string;
      accountToUUID?: string;
      vatRateUUID?: string;
      labels?: string;
      expenseAndRevenueTurnover: boolean;
    };
  }>()
);

export const downloadGlPdf = createAction(
  '[Fin Statements/API] Download General Ledger PDF',
  props<{
    payload: {
      from: string;
      to?: string;
      fromAccount?: number;
      toAccount?: number;
      vatRateId?: string;
      labels?: string;
      expenseAndRevenueTurnover: boolean;
    };
  }>()
);

export const downloadBsPdf = createAction(
  '[Fin Statements/API] Download Balance Sheet PDF',
  props<{ from: string; to: string; detailed: boolean }>()
);

export const downloadPlPdf = createAction(
  '[Fin Statements/API] Download Profit And Loss PDF',
  props<{ from: string; to: string; detailed: boolean; labels: string[] }>()
);

export const downloadStatementOfAccountsPdf = createAction(
  '[Fin Statements/API] Download Statement Of Accounts PDF',
  props<{
    from: string;
    to: string;
    fromAccount: string;
    toAccount: string;
  }>()
);

export const downloadStatementOfAccountsPdfSuccess = createAction(
  '[Fin Statements/API] Download Statement Of Accounts PDF Success',
  props<{ url: string }>()
);
export const downloadStatementOfAccountsPdfFailure = createAction(
  '[Fin Statements/API] Download Statement Of Accounts PDF Failure'
);
