import { Component, effect, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { LayoutService } from '@app/_layouts';
import { CURRENT_COMPANY_STORE } from '@domains/company';

import { CoreRouteLayoutConfig } from './constants';

@Component({
  selector: 'app-core-container',
  template: ` <router-outlet></router-outlet>`,
  imports: [RouterOutlet],
})
export class CoreContainerComponent {
  private layoutService = inject(LayoutService);

  private readonly companyStore = inject(CURRENT_COMPANY_STORE);
  private readonly companyID = this.companyStore.id;

  constructor() {
    effect(() => {
      const companyID = this.companyID();
      this.layoutService.updateLayoutState({
        ...CoreRouteLayoutConfig,
        basePath: companyID || '',
      });
    });
  }
}
