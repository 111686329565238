import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { LayoutService } from '@app/_layouts';
import { ExpenseService } from '@api/expense.service';

import * as ExpenseActions from '../store/actions/expense.actions';

@Injectable({
  providedIn: 'root',
})
export class ExpenseGuard {
  private router = inject(Router);
  private store = inject(Store);
  private layout = inject(LayoutService);
  private expenseService = inject(ExpenseService);


  hasEntityInApi(uuid: string): Observable<boolean | UrlTree> {
    return this.expenseService.getById(uuid).pipe(
      map((payload) => ExpenseActions.loadByIdSuccess({ payload })),
      tap((action) => {
        this.layout.setBreadcrumbEntityLabel(`#${action.payload.sequenceNumber}`);
        this.store.dispatch(action);
      }),
      map((entity) => !!entity),
      catchError(() => of(this.router.createUrlTree(['/404'])))
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const uuid = route.paramMap.get('uuid');
    return this.hasEntityInApi(uuid);
  }
}
