import { Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import {
  catchError,
  map,
  tap,
  take,
  delay,
  mergeMap,
  exhaustMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of, from } from 'rxjs';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AuthService } from '@api/auth.service';
import { UserService } from '@api/user.service';
import { NotificationService } from '@shared/notification/notification.service';
import { CreatePasswordDialog } from '@app/passwordless-auth/create-password/create-password.dialog';

import * as AuthActions from '../actions/auth.actions';
import { createUserSuccess } from '../actions/user.actions';
import { addUserToBureaus } from '../actions/bureau.actions';
import { selectValidators } from '../selectors/util.selectors';
import { WINDOW } from '@shared/tokens';

@Injectable()
export class AuthEffects {
  private window = inject<Window>(WINDOW);
  private ntf = inject(NotificationService);
  private router = inject(Router);
  private actions$ = inject(Actions);
  private auth = inject(AngularFireAuth);
  private authService = inject(AuthService);
  private userService = inject(UserService);
  private dialog = inject(MatDialog);
  private store = inject(Store);

  getIdTokenResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getIdTokenResult),
      exhaustMap(() =>
        this.auth.idTokenResult.pipe(
          take(1),
          map((res) => {
            if (res) {
              return AuthActions.getIdTokenResultSuccess({
                id: res.claims['user-uuid'],
              });
            }
            return AuthActions.getIdTokenResultFailure({ error: null });
          }),
          catchError((error) => of(AuthActions.getIdTokenResultFailure(error)))
        )
      )
    );
  });

  getIdTokenResultSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getIdTokenResultSuccess),
      map(({ id }) => {
        return AuthActions.getUser({ id });
      })
    )
  );

  getUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUser),
      exhaustMap(({ id }) =>
        this.userService.getUser(id).pipe(
          map((payload) => AuthActions.getUserSuccess({ payload })),
          catchError((error) => of(AuthActions.getUserFailure(error)))
        )
      )
    );
  });

  signIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signIn),
      exhaustMap(({ payload }) =>
        from(this.authService.signIn(payload)).pipe(
          map(() => AuthActions.signInSuccess()),
          catchError(({ code }) => of(AuthActions.signInFailure({ error: code })))
        )
      )
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      exhaustMap(() =>
        from(this.authService.logout()).pipe(
          map(() => AuthActions.logoutSuccess()),
          catchError((error) => of(AuthActions.logoutFailure(error)))
        )
      )
    );
  });

  forgotPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.forgotPassword),
      exhaustMap(({ payload }) =>
        from(this.authService.forgotPassword(payload)).pipe(
          map(() => AuthActions.forgotSuccess()),
          catchError(({ code }) => of(AuthActions.forgotFailure({ error: code })))
        )
      )
    );
  });

  verifyUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.verifyUser),
      exhaustMap(({ payload: { email, password, confirmPassword, legalEntityId } }) =>
        this.authService.verify({ password, confirmPassword, legalEntityId }).pipe(
          map((user) => AuthActions.verifyUserSuccess({ payload: { email, password } })),
          catchError((error) => of(AuthActions.verifyUserFailure(error)))
        )
      )
    );
  });

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.updateUser),
      exhaustMap((action) =>
        this.userService.updateUser(action.payload).pipe(
          map((payload) => AuthActions.updateUserSuccess({ payload })),
          catchError((error) => of(AuthActions.updateUserFailure(error)))
        )
      )
    );
  });

  signInWithEmailLink$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signInWithEmailLink),
      exhaustMap(({ email, url }) =>
        from(this.authService.signInWithEmailLink(email, url)).pipe(
          map(() => AuthActions.signInWithEmailLinkSuccess({ email })),
          catchError((error) => of(AuthActions.signInWithEmailLinkFailure(error)))
        )
      )
    );
  });

  sendSignInWithEmailLink$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.sendSignInWithEmailLink),
      mergeMap(({ payload: { roleId, email, bureauIds } }) =>
        this.authService.invite({ email }).pipe(
          map((user) =>
            AuthActions.sendSignInWithEmailLinkSuccess({
              payload: user,
              bureauIds,
              roleId,
            })
          ),
          catchError((error) => of(AuthActions.sendSignInWithEmailLinkFailure(error)))
        )
      )
    );
  });

  sendSignInWithEmailLinkSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.sendSignInWithEmailLinkSuccess),
      delay(1000),
      tap(() => {
        this.ntf.success(['ntf.send_success', 'text.invitation']);
      }),
      mergeMap(({ payload, roleId, bureauIds }) => {
        const actions: any[] = [createUserSuccess({ payload })];
        if (roleId && bureauIds.length) {
          actions.push(
            addUserToBureaus({
              payload: { userId: payload.uuid, roleId, bureauIds },
            })
          );
        }
        return actions;
      })
    )
  );

  signInWithEmailLinkFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signInWithEmailLinkFailure),
        tap(() => this.ntf.error('ntf.email_dismatch'))
      ),
    { dispatch: false }
  );

  signInWithEmailLinkSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signInWithEmailLinkSuccess),
      withLatestFrom(this.store.select(selectValidators)),
      exhaustMap(([{ email }, configs]) => {
        const ref = this.dialog.open(CreatePasswordDialog, {
          width: '450px',
          data: { email, configs },
        });
        return ref.afterClosed();
      }),
      map((payload) => AuthActions.verifyUser(payload))
    )
  );

  verifyUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.verifyUserSuccess),
      map(({ payload }) => AuthActions.signIn({ payload }))
    )
  );

  signInSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signInSuccess),
      tap(() => this.router.navigate(['/'])),
      map(() => AuthActions.getIdTokenResult())
    )
  );

  updateUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.updateUserSuccess),
        tap(() => this.ntf.success(['ntf.update_success', 'text.profile']))
      ),
    { dispatch: false }
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutSuccess),
        tap(() => {
          this.router.navigate(['sign-in']);
          this.window.location.reload();
        })
      ),
    { dispatch: false }
  );
}
