@let vm =
  {
    company: company(),
    authUser: authUser(),
    showCompanyInfo: showCompanyInfo(),
    sidebarMinimized: sidebarMinimized(),
  };

@if (vm.showCompanyInfo) {
  @if (vm.company) {
    @let companyName = vm.company.name;

    <span [matTooltip]="companyName" class="header-symbol">
      {{ companyName | firstLetters: 2 }}
    </span>
    @if (!vm.sidebarMinimized) {
      <div class="company-info-row">
        <span (click)="switchCompany()" [matTooltip]="companyName" class="header-label">{{
          companyName
        }}</span>
        <button (click)="updateCompanyInfo(vm.company.id)" color="primary" mat-flat-button>
          <i class="tr icon-pencil"></i>
        </button>
      </div>
    }
  } @else {
    <span class="header-symbol">
      <mat-progress-spinner
        mode="indeterminate"
        color="primary"
        diameter="16"
      ></mat-progress-spinner>
    </span>
  }
} @else if (vm.authUser) {
  @let displayName = getAuthUserDisplayName();
  <span [matTooltip]="displayName" class="header-symbol pointer-default">
    {{ getAuthUserLetter() }}
  </span>
  @if (!vm.sidebarMinimized) {
    <div class="company-info-row">
      <span [matTooltip]="displayName" class="header-label pointer-default fs-18">{{
        displayName
      }}</span>
    </div>
  }
}
