import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  input,
  effect,
  untracked,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import {
  CompanyApiService,
  CompanyContractStatusIndicator,
  CompanyPopupsService,
  ICompany,
  ICompanyScheduledContract,
} from '@domains/company';
import { sortCompareDate } from '@shared/utils';
import { LoaderOverlayDirective } from '@shared/global-directives/loader-overlay.directive';
import { TrDatePipe } from '@shared/global-pipes/tr-date.pipe';
import { SharedModule } from '@shared/shared.module';

type Entity = ICompanyScheduledContract & {
  current?: boolean;
};

@Component({
  selector: 'company-contract-status',
  imports: [
    TranslateModule,
    CompanyContractStatusIndicator,
    MatButtonModule,
    MatTooltipModule,
    LoaderOverlayDirective,
    SharedModule,
  ],
  template: `
    @if (loading) {
      <div [loaderOverlay]="true" loaderIndicatorSize="small">
        <div style="height: 24px; width: 24px"></div>
      </div>
    } @else {
      <button
        [matTooltip]="tooltipText"
        (click)="showContractStatusHistory()"
        class="contract-status-btn active no-animate"
        [class.bordered]="futureContract"
      >
        <company-contract-status-indicator
          [status]="currentContract?.status || company().contractStatus"
          [showLabel]="false"
          [iconSize]="'24px'"
        ></company-contract-status-indicator>

        @if (futureContract) {
          <i class="tr icon-arrow-right"></i>
          <company-contract-status-indicator
            [status]="futureContract.status"
            [showLabel]="false"
            [iconSize]="'24px'"
          ></company-contract-status-indicator>
        }
      </button>
    }
  `,
  styles: [
    `
      .contract-status-btn {
        align-items: center;
        justify-content: center;
        background: none;
        cursor: pointer;
        display: flex;
        gap: 6px;
        height: 32px;
        width: unset;
        border: none;

        &.bordered {
          border: 1px solid var(--tr-orange);
          border-radius: 4px;
        }
      }

      .icon-rotate {
        color: var(--tr-orange) !important;
        font-size: 24px;
      }

      .icon-arrow-right {
        color: black !important;
        font-size: 18px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyContractStatus {
  private popupsService = inject(CompanyPopupsService);
  private companyApiService = inject(CompanyApiService);
  private translateService = inject(TranslateService);
  private chd = inject(ChangeDetectorRef);

  readonly company = input<ICompany>(undefined);

  loading: boolean;
  contracts: Entity[] = [];
  currentContract: Entity;
  futureContract: Entity;
  tooltipText = '';
  trDatePipe = new TrDatePipe();

  private fetchContractsHistory(company: ICompany): void {
    if (company) {
      this.loading = true;
      this.companyApiService.getContractsHistoryById(company.id).subscribe((contracts) => {
        const sorted = this.sortContactsByDate(contracts);
        const marked = this.findAndMarkCurrentStatus(sorted);

        this.contracts = marked;
        this.setTooltipText();

        this.loading = false;
        this.chd.detectChanges();
      });
    }
  }

  setTooltipText() {
    this.tooltipText = `companies.contract_status_options.`;

    if (!this.currentContract) {
      this.tooltipText = this.translateService.instant(
        this.tooltipText + this.company().contractStatus.toLocaleLowerCase()
      );
    }

    if (this.currentContract && !this.futureContract) {
      this.tooltipText = `
        ${this.translateService.instant(this.tooltipText + this.currentContract.status.toLocaleLowerCase()) + ' ' + this.trDatePipe.transform(this.currentContract.date)}
        ${this.currentContract.comment}
      `;
    }

    if (this.currentContract && this.futureContract) {
      this.tooltipText = `
        ${this.translateService.instant(this.tooltipText + this.currentContract.status.toLocaleLowerCase()) + ' ' + this.trDatePipe.transform(this.currentContract.date)}
        ${this.currentContract.comment}
        ${this.translateService.instant(this.tooltipText + this.futureContract.status.toLocaleLowerCase()) + ' ' + this.trDatePipe.transform(this.futureContract.date)}
        ${this.futureContract.comment}
      `;
    }
  }

  reset() {
    this.currentContract = null;
    this.futureContract = null;
    this.contracts = [];
    this.tooltipText = '';
  }

  showContractStatusHistory(): void {
    this.popupsService.showStatusesHistoryPopup(this.company());
  }

  private sortContactsByDate(contacts: Entity[]) {
    return [...(contacts || [])].sort((a, b) => sortCompareDate(a.date, b.date));
  }

  findAndMarkCurrentStatus(contracts: Entity[]) {
    const today = new Date();
    const pastContracts = contracts.filter((contract) => new Date(contract.date) < today);
    const futureContracts = contracts.filter((contract) => new Date(contract.date) > today);

    if (pastContracts.length) {
      const closestContract = pastContracts.reduce((prev, curr) =>
        Math.abs(new Date(curr.date).getTime() - today.getTime()) <
        Math.abs(new Date(prev.date).getTime() - today.getTime())
          ? curr
          : prev
      );

      closestContract.current = true;
      this.currentContract = closestContract;
    }

    if (futureContracts.length) {
      const closestFutureContract = futureContracts.reduce((prev, curr) =>
        Math.abs(new Date(curr.date).getTime() - today.getTime()) <
        Math.abs(new Date(prev.date).getTime() - today.getTime())
          ? curr
          : prev
      );

      this.futureContract = closestFutureContract;
    }

    return contracts;
  }

  constructor() {
    effect(() => {
      const company = this.company();
      untracked(() => {
        this.reset();
        this.fetchContractsHistory(company);
      });
    });
  }
}
