import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PermissionsService } from '../_api/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class ClientsGuard {
  private permissions = inject(PermissionsService);


  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return of(true).pipe(switchMap(() => this.permissions.accessGuard('client')));
  }
}
